@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
  .comfortaa {
    font-family: 'Comfortaa', cursive !important;
  }
  
    @keyframes slideIn {
      0% {
        opacity: .35;
        transform: translateX(-1px);
      }
      50% {
        opacity: 1.0;
      }
    
      100% {
        transform: translateX(3px);
        opacity: .35;
      }
    }
  .wisp-in {
    animation: 1s ease-out 0s 1 slideIn;
  }
}

html, button {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 20px;
  margin-left: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(94, 150, 105);
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(76, 189, 100);
  border-radius: 5px;
}