/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* hopefully eliminating default rounding on iphone inputs */
input {
    -webkit-border-radius:0; 
    border-radius:0;
}

.semi-circle {
  clip-path: polygon(34% 2%,50.00% 0.00%,68% 3%,83.00% 12.00%,95% 26%,100% 43%,0.00% 43.00%,8% 22%,21.00% 8.00%);
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  outline: none;
}

button:focus {
  outline: none;
}